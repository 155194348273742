<template>
  <div
    class="fullscreen bg-white text-white text-center q-pa-md flex flex-center"
  >
    <div class="page-container">
      <div class="text-center">
        <q-img
          style="width:258px;height:auto;"
          class="bg-hero"
          position="0 50%"
          spinner-color="white"
          src="caminho-sem-saida.png"
        />
        <h3 class="q-mb-none">Erro 404</h3>
      </div>

      <div class="content q-mt-sm">
        Pedimos desculpas, mas o endereço que inseriu não se encontra
        disponível.
      </div>
      <div class="row q-col-gutter-md q-mt-sm justify-center">
        <div class="col-md-6 col-12">
          <!-- history go back -->
          <q-btn
            class="btn-primary"
            color="primary"
            text-color="white"
            size="md"
            unelevated
            @click="hasHistory()
            ? $router.go(-1)
            : $router.push('/')"
            to=""
            label="Voltar a página anterior"
            no-caps
          />
        </div>
        <div class="col-md-6 col-12">
          <q-btn
            class="btn-primary"
            color="primary"
            text-color="white"
            size="md"
            unelevated
            to="/"
            label="Ir para a página inicial"
            no-caps
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods: {
    hasHistory () { return window.history.length > 2 },
    reloadPage () {
      window.location.reload()
    }
  }
}
</script>
<style lang="scss">
.btn-sencondary .block {
  color: var(--q-color-primary) !important;
}
.page-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
}
h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #76528f;
  margin-top: 40px;
}
.content {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #273036;
}
@media screen and (max-width: 599px) {
  .btn-primary{
    width: 100%;
  }
}
</style>
